.footer-view {
  height: 42px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.13);
  overflow: hidden;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
  font-size: 14px;
  // color: #fff;
}

.footer-alerts {
  min-width: 100px;
}

.clipboard-alert {
  span {
    color: #191919;
    font-weight: 600;
  }
}
