@import "../../../common-styles/layout/mini-pop-up.style.scss";
@import "../../../common-styles/layout/td-flags.style.scss";

.load-readlog-btn {
  padding: 3px 5px;
  border-bottom-width: 1.5px !important;
  margin-left: 0px !important;

  svg {
    transform: translateY(1px);
    margin-right: 4px;
  }
}
