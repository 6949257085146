$pu-nav-bg-color: #fff;
$pu-nav-active-color: #eaeaea;
$pu-nav-active-color-2: #eaeaea;

.pop-up-wrapper {
  position: absolute;
  z-index: 9000;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(15, 15, 15, 0.753);
}

.pop-up-container {
  position: relative;
  background-color: #fff;
  // overflow: hidden;

  min-width: 100%;
  min-height: 100%;
}

// -------------- ------------- ----------------- ------------- -------------- ------------- -----------------
// -------------- ------------- ----------------- POP UP HEADER -------------- ------------- -----------------
// -------------- ------------- ----------------- ------------- -------------- ------------- -----------------
$pu-header-height: 50px;
$pu-header-margin-bottom: 2px;
.pop-up-header {
  height: $pu-header-height;
  // background-color: #1c4e80;
  background-color: #ffffff;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;

  margin-bottom: $pu-header-margin-bottom;
  box-shadow: 5px 5px 2px 2px rgb(221, 221, 221);

  .pu-header-left {
    display: flex;
    align-items: center;
  }

  .pop-up-heading,
  .pop-up-close {
    display: inline-block;
    // color: rgb(247, 247, 247);
  }

  .pop-up-close {
    border: 1.5px solid $pu-nav-active-color;
    padding: 4px 8px;
    border-radius: 5px;
    font-size: 12px;

    &:hover {
      cursor: pointer;
      // background-color: $pu-nav-active-color;
      background: linear-gradient(60deg, $pu-nav-active-color, $pu-nav-active-color-2);
      color: rgb(0, 0, 0);
      // border-color: rgb(194, 194, 194);
    }
  }

  .pop-up-heading {
    margin-left: 12px;
  }

  .refresh {
    margin: 0 15px;
    display: inline-block;
    padding: 3.2px 10px;
    border-right: 1px solid #adadad;
    cursor: pointer;
    color: #585858;

    &:first-of-type {
      border-left: 1px solid #adadad;
    }

    &:hover {
      background-color: #f8f8f8;
    }
  }

  // ########################
  // ###### POP UP NAV ######
  // ########################
  .pop-up-nav {
    display: inline-block;
    .nav-item {
      border-radius: 3px;
      display: inline-block;
      padding: 3px 5px;
      margin-right: 8px;
      background-color: $pu-nav-bg-color;
      font-weight: 700;
      letter-spacing: 0.8px;

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        // background-color: $pu-nav-active-color;
        background: linear-gradient(60deg, $pu-nav-active-color, $pu-nav-active-color-2);
        cursor: pointer;
      }

      &.pu-active-nav {
        // background-color: $pu-nav-active-color;
        background: linear-gradient(60deg, $pu-nav-active-color, $pu-nav-active-color-2);
      }
    }
  }
}

// -------------- ------------- ----------------- ----------- -------------- ------------- -----------------
// -------------- ------------- ----------------- POP UP BODY -------------- ------------- -----------------
// -------------- ------------- ----------------- ----------- -------------- ------------- -----------------
.pop-up-body {
  // position: relative;
  height: calc(100vh - (#{$pu-header-height}));
  background-color: rgb(232, 235, 242);
  overflow-y: auto;
}
