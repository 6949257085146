@import "../colors/state-flat-colors";

// Table Data Flags
.td-flags {
  color: #fff;
  text-align: center;
  text-align: center;
  display: inline-block;
  font-size: 11px;
  // font-weight: 700;
  letter-spacing: 0.8px;
  padding: 3px 7px;
  border-radius: 3px;
  box-shadow: 0.5px 0.5px 2px 0.6px rgba(26, 26, 26, 0.3);
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 12px;

  &:first-of-type {
    // margin-left: 6px;
  }

  &-add-left-margin {
    margin-left: 15px !important;
  }
}

.active {
  background-color: $active-color;
}

.blocked {
  background-color: $blocked-color;
}

.refunded {
  background-color: $refunded-color;
}

.transferred {
  background-color: $transferred-color;
}

.modified {
  background-color: $modified-color;
}

.activated {
  background-color: $activated-color;
}

.leaked {
  background-color: $leaked-color;
}
