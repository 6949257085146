@import "../../common-styles/layout/td-flags.style.scss";
@import "../../common-styles/colors/state-flat-colors";

.table-wrapper {
  border-radius: 3px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.15);
  margin: 1.5px 0;
  overflow: hidden;
}

.data-table {
  font-size: 14.2px;
  width: 100%;
  border-spacing: 0px;
  background-color: #fff;

  .even-tr {
    background: linear-gradient(60deg, #f7f7f7, #f7f7f7);
  }

  .highlight-tr {
    box-shadow: inset 0 0 2px 1px #bebebe;
  }

  td {
    padding: 5px;
    border: 2px solid transparent;
    vertical-align: middle;
    font-size: 13px;
    height: 60px;
  }

  .operation-td {
    padding: 0px;
    text-align: center;

    .operation-icon {
      color: #666666;
    }

    &:hover {
      cursor: pointer;
      background: linear-gradient(60deg, #e7e7e7, #d8d8d8);

      .operation-icon {
        color: rgb(22, 22, 22);
      }
    }
  }

  .interactive-td {
    cursor: pointer;
    user-select: none;

    &:hover {
      color: rgb(30, 97, 199);
    }
  }

  .td-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  // Table Data Status
  .td-status {
    position: relative;
    span {
      margin-left: 20px;
    }

    span::before {
      content: "";
      position: absolute;
      left: 9px;
      top: 42%;
      height: 9px;
      width: 9px;
      border-radius: 50%;
    }

    &-active {
      color: $active-color;

      span::before {
        background-color: $active-color;
      }
    }

    &-blocked {
      color: $blocked-color;

      span::before {
        background-color: $blocked-color;
      }
    }

    &-refunded {
      color: $refunded-color;

      span::before {
        background-color: $refunded-color;
      }
    }

    &-transferred {
      color: $transferred-color;

      span::before {
        background-color: $transferred-color;
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  .data-table {
    .td-flex {
      .td-flex-group {
        max-width: 80px;
        text-align: right;
        margin-right: 5px;

        .td-flags {
          margin: 5px 0;
        }
      }
    }
  }
}

.table-headings {
  background: linear-gradient(60deg, #858585, #797979);

  th {
    color: #fff;
    letter-spacing: 0.5px;
    padding-left: 8px;
    padding-right: 8px;
    user-select: none;
    text-align: left;
    height: 40px;
  }

  #operations {
    min-width: 35px;
    width: 35px;
  }

  #docID,
  #licenseKey,
  #hardwareToken {
    min-width: 300px;
  }

  #flags {
    min-width: 200px;
  }

  #createdAt,
  #modifiedAt,
  #lastReadAt {
    min-width: 170px;
    width: 170px;
  }

  #status {
    width: 90px;
    min-width: 85px;
  }

  .sorting-col {
    cursor: pointer;

    p {
      display: inline-block;
    }

    .icon {
      margin-left: 12px;
      display: inline-block;
      width: 8.8px;

      svg {
        display: none;
      }

      .active-col {
        display: inline-block;
        color: #fff !important;
      }
    }

    &:hover {
      .icon {
        svg {
          display: inline-block;
          color: #fff !important;
        }
      }
    }
  }
}
