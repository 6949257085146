.fixed-pop-up-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(15, 15, 15, 0.7);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .fixed-pop-up {
    position: relative;
    width: 94%;
    height: 94%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 5px;

    .fpu-close-btn {
      color: #707070;
      position: absolute;
      display: inline-block;
      top: 2px;
      right: 3px;
      cursor: pointer;
      font-size: 21px;

      &:hover {
        color: #494949;
      }
    }
  }
}
