.login-page {
  width: 100%;
  min-height: 100vh;
  // background: linear-gradient(60deg, rgb(28, 78, 128), rgba(2, 22, 56, 0.95));
  background: linear-gradient(60deg, #161616, #1b1b1b);
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .login {
    padding: 30px;
    background-color: rgb(28, 78, 128);
    box-shadow: 0 1px 1px 0 rgba(2, 22, 56, 0.5), 0 1px 3px 1px rgba(60, 64, 67, 0.2);
    border-radius: 4px;
    width: 325px;

    form {
      display: flex;
      flex-direction: column;
      margin: 15px 0;

      input {
        margin: 10px 0;
        padding: 5px;
      }
    }
  }
}

button {
  margin: 10px 0;
  padding: 5px;
  cursor: pointer;
}

.google-button {
  background-color: #db4437;
  color: #fff;
}

@media only screen and (max-width: 325px) {
  .login-page {
    .login {
      padding: 18px;
      width: 100%;
    }
  }
}
