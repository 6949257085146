$user-info-height: 180px;
$box-shadow-color: rgb(28, 78, 128);

.user-info {
  min-height: $user-info-height;
  // border-bottom: 1px solid #2c2c2c;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  // box-shadow: 0px 0px 0px 1px $box-shadow-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-items: center;

  .user-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .user-avatar {
    display: inline-block;
    min-width: 55px;
    min-height: 55px;
    text-align: center;
    margin-bottom: 8px;
    padding: 15px;
    color: #fff;
    font-size: 24px;
    letter-spacing: 0.6px;
    border-radius: 100px;
    background-color: rgb(122, 6, 126);
    background-position: center;
    background-size: cover;
  }

  // .user-details {
  // display: inline-block;
  text-align: center;

  .user-name {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
  }

  .user-email {
    color: #aaaaaa;
    font-size: 11px;
    margin-bottom: 2px;
  }

  .logout {
    display: inline-block;
    font-size: 11px;
    color: rgb(151, 151, 151);
    user-select: none;
    cursor: pointer;

    &:hover {
      color: rgb(206, 206, 206);
    }
  }
  // }
}

.unset-min-height {
  min-height: 100%;
}
