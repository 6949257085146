.toolbar {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
  font-size: 14px;
  // color: #fff;

  p {
    display: inline;
    margin-right: 5px;
  }

  // .button {
  //   display: inline-block;
  //   padding: 3px 5px;
  //   background-color: #9e9a98;
  //   border-radius: 2px;
  //   cursor: pointer;
  //   user-select: none;
  // }

  // .button:active {
  //   background-color: #919191;
  // }

  .search-button,
  .search-close-button,
  .date-range-close-button {
    cursor: pointer;
    color: rgb(148, 148, 148);

    &:hover {
      color: rgb(102, 102, 102);
    }
  }

  .search-close-button {
    margin-right: 7px;
  }

  .search-button {
    margin-right: 7px;
    margin-top: -1px;
  }

  .date-range-close-button {
    margin-right: 0;
    margin-left: 5px;
  }
}

.search {
  display: inline-block;
  .search-wrapper {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    border: 1px solid rgba(27, 27, 27, 0.3);
    border-radius: 10px;
    margin: 0 3px;

    select {
      position: relative;
      border-radius: 10px;
      border: none;
      outline: none;
      font-size: 12px;
      flex-flow: column-reverse;
    }

    input {
      overflow: hidden;
      // border-radius: 10px;
      margin-right: 7px;
      outline: none;
      min-width: 320px;
      display: inline;
      padding: 5.5px 8px;
      font-size: 11.8px;
      border: none;
      border-left: 1px solid rgb(218, 218, 218);
      border-right: 1px solid rgb(218, 218, 218);
      background-color: #fff !important;
    }
  }
}

.tb-button-wrapper {
  display: inline;
  margin-right: 10px;
}

.toolbar-button {
  display: inline;
  padding: 2.8px 10px;
  border-right: 1px solid #adadad;
  cursor: pointer;
  color: #585858;

  &:first-of-type {
    border-left: 1px solid #adadad;
  }

  &:hover {
    background-color: #f8f8f8;
  }
}

.filter-by {
  display: inline-flex;
  align-items: center;
  // padding-right: 10px;
  // border-right: 1px solid #adadad;

  .fb-title {
    color: #fff;
    letter-spacing: 0.7px;
    background-color: #9b9b9b;
    padding: 3px 5px;
    border-radius: 3px;
    margin-right: 10px;
  }

  .fb-label {
    font-weight: 600;
  }

  .fbl-disabled {
    user-select: none;
    color: #c2c2c2 !important;
  }

  .by-date {
    // margin-right: 10px;
    display: inline-flex;
    align-items: center;
    padding-right: 10px;
    border-right: 1px solid #adadad;

    select {
      width: 108px;
    }
  }

  .by-status {
    padding: 0 10px;
    border-right: 1px solid #adadad;
  }
}
