.sentinel-overview {
  width: 100%;
  height: inherit;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .so-block {
    // flex-grow: 1;
    min-width: 50%;

    .info-block {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin: 12px;
      min-height: calc(100% - (12px * 2));

      .ib-body {
        height: 100%;
        overflow-x: auto;
      }
    }
  }
}
