.welcome-back {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  // background: linear-gradient(60deg, rgb(28, 78, 128), rgba(2, 22, 56, 0.95));
  background: linear-gradient(60deg, #161616, #1b1b1b);
}
