$active-color: #05a005;
$active-bb-color: #098109;
$active-hover-color: #129412;
$blocked-color: #ee1c2e;
$blocked-bb-color: #b1121f;
$blocked-hover-color: #e41526;
$refunded-color: #f38f32;
$refunded-bb-color: #cc6f17;
$refunded-hover-color: #ee892b;
$transferred-color: #09a3cc;
$transferred-bb-color: #1686a5;
$transferred-hover-color: #0f9cc4;
$leaked-color: #6942f8;

$general-btn-color: #0575ce;
$general-btn-bb-color: #0b5a9b;
$general-btn-hover-color: #0b71c5;

$activated-color: #8d8d8d;
$modified-color: #414141;

@mixin button-layout {
  margin-left: 10px;
  user-select: none;
  padding: 8px 10px 6px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 0.8px;
  font-size: 13.5px;
  color: #fff;
}

.action-btn {
  @include button-layout;

  &.inactive {
    pointer-events: none;
    // cursor: not-allowed;
    color: rgb(156, 156, 156);

    background-color: rgb(226, 226, 226);
    border-bottom: 3.5px solid rgb(199, 199, 199);
  }

  &.hide-btn {
    display: none;
  }

  &.general-btn,
  &.block-btn,
  &.unblock-btn,
  &.refund-btn,
  &.transfer-btn {
    box-shadow: 0.5px 0.5px 3px 1px rgba(0, 0, 0, 0.2);

    &:active {
      box-shadow: 0.5px 0.5px 3px 1px rgba(0, 0, 0, 0);
    }
  }

  &.general-btn {
    background-color: $general-btn-color;
    border-bottom: 3.5px solid $general-btn-bb-color;
    text-shadow: 0px -2px $general-btn-bb-color;

    &:hover {
      background-color: $general-btn-hover-color;
    }
  }

  &.block-btn {
    background-color: $blocked-color;
    border-bottom: 3px solid $blocked-bb-color;
    text-shadow: 0px -2px $blocked-bb-color;

    &:hover {
      background-color: $blocked-hover-color;
    }
  }

  &.refund-btn {
    background-color: $refunded-color;
    border-bottom: 3px solid $refunded-bb-color;
    text-shadow: 0px -2px $refunded-bb-color;

    &:hover {
      background-color: $refunded-hover-color;
    }
  }

  &.transfer-btn {
    background-color: $transferred-color;
    border-bottom: 3px solid $transferred-bb-color;
    text-shadow: 0px -2px $transferred-bb-color;

    &:hover {
      background-color: $transferred-hover-color;
    }
  }

  &.unblock-btn {
    background-color: $active-color;
    border-bottom: 3px solid $active-bb-color;
    text-shadow: 0px -2px $active-bb-color;

    &:hover {
      background-color: $active-hover-color;
    }
  }
}
