// EPIC CHARACTER GROUP
.epic-character-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 3px 0;
  font-weight: 600;

  .epic-character {
    font-size: 48px;
    color: rgb(99, 99, 99);
  }

  .tiny-character {
    font-size: 14px;
    margin-left: 5px;
  }
}
