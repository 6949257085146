.date-range-select {
  position: relative;
  .drs-trigger {
    cursor: pointer;
    text-align: center;
    width: 145px;
    margin-right: 5px;
    padding: 4px 6px;
    border-radius: 3px;
    background-color: rgba(2, 110, 220, 0.05);

    &:hover {
      background-color: rgba(2, 110, 220, 0.11);
    }
  }

  .drs-options {
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    z-index: 9999;
    right: -30%;
    top: 33px;
    width: 250px;
    // border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 3px 0.5px rgba(0, 0, 0, 0.3);

    &-align-right {
      right: 0;
    }

    &-align-left {
      left: 0;
      right: unset;
    }

    .drso-presets {
      padding: 5px 5px 10px;
      margin: 5px;
      text-align: center;
      font-size: 13.3px;
      border-bottom: 0.5px solid rgba(31, 31, 31, 0.15);

      select {
        width: 100%;
      }
    }

    .drso-footer {
      // display: flex;
      // justify-content: space-between;
      margin: 12px 10px 10px;

      .drso-button {
        text-align: center;
        display: inline-block;
        user-select: none;
        color: rgb(66, 66, 66);
        padding: 5px 8px;
        border-radius: 4px;
        width: calc((100% - 10px) / 2);
        color: #fff;
        margin: 0;
        cursor: pointer;
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);

        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }

      .btn-ok {
        background-color: #0575ce;
        border-bottom: 2.3px solid #0b5a9b;
        text-shadow: 0px -2px #0b5a9b;

        &:hover {
          background-color: #0b71c5;
        }
      }

      .btn-cancel {
        background-color: #707070;
        border-bottom: 2.3px solid #505050;
        text-shadow: 0px -2px #505050;

        &:hover {
          background-color: #666666;
        }
      }
    }

    .react-calendar {
      width: 230px;
      margin: 10px;
      border: none;
      font-family: "Roboto", sans-serif !important;

      .react-calendar__navigation__label {
        font-size: 12.4px;
      }
      .react-calendar__month-view__weekdays__weekday {
        font-size: 10.4px;
      }

      .react-calendar__navigation button[disabled],
      .react-calendar__tile:disabled {
        background-color: #f7f7f7;
        color: rgb(170, 170, 170) !important;
        cursor: default;
      }

      .react-calendar__month-view__days__day--neighboringMonth {
        color: #757575 !important;
      }

      .react-calendar__navigation button {
        min-width: auto;
      }

      .react-calendar__tile--active,
      .react-calendar__tile--hasActive {
        background: rgba(2, 110, 220, 0.28);
        color: rgb(0, 0, 0);
      }

      .react-calendar__month-view__days__day--weekend {
        color: rgb(0, 0, 0);
      }

      abbr[title] {
        text-decoration: none;
      }
    }
  }
}

.date-range-select-disabled {
  color: #c2c2c2 !important;
  user-select: none;
  .drs-trigger-disabled {
    pointer-events: none;

    &:hover {
      cursor: default;
      background-color: rgba(2, 110, 220, 0.05);
    }
  }
}
