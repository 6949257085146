$toolbar-height: 58px;

.toolbar-view {
  height: $toolbar-height;
  // margin-bottom: 2px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);

  width: 100%;
  // background: linear-gradient(60deg, rgb(28, 78, 128), #1c4e80);
  background-color: #fff;
}
