.add-key-button {
  position: absolute;
  bottom: 0px;
  right: 14px;

  padding: 8px 10px;
  border-radius: 50%;
  background-color: #1e6cbb;
  color: #fff;
  border: 1.5px solid;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);

  &:hover {
    padding: 12px 15px;
    cursor: pointer;
  }

  &:active {
    background-color: #2076cc;
    border-color: blanchedalmond;
  }
}

@import "../../common-styles/colors/state-gradient-colors";
.custom-check-wrapper {
  display: inline-block;
  .custom-check-element {
    display: inline-block;
    padding: 2px 5px;
    color: #fff;
    font-size: 13px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    user-select: none;
    background-color: rgb(150, 150, 150);

    &:not(:last-of-type) {
      margin-right: 7px;
    }

    &.cce-checked {
      &.cce-block {
        background-color: $blocked-color;
      }

      &.cce-refund {
        background-color: $refunded-color;
      }
    }
  }
}
