$created-color: #999999;
$created-color-2: #969696;
$active-color: #05a058;
$active-color-2: #04a158;
$blocked-color: #f54343;
$blocked-color-2: #f53f3f;
$refunded-color: #f38f32;
$refunded-color-2: #f59235;
$transferred-color: #09a3cc;
$transferred-color-2: #0a96bd;
$leaked-color: #957af8;
$leaked-color-2: #8d70f5;
