.signup-page {
  width: 100%;
  min-height: 100vh;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup {
  min-width: 400px;
  padding: 30px;
  background-color: rgb(28, 78, 128);
  box-shadow: 0 1px 1px 0 rgba(2, 22, 56, 0.5), 0 1px 3px 1px rgba(60, 64, 67, 0.2);
  border-radius: 4px;

  form {
    display: flex;
    flex-direction: column;
    margin: 15px 0;

    input {
      margin: 10px 0;
      padding: 5px;
    }

    .input-error {
      border: 1px solid red;
    }
  }

  h6 {
    margin-bottom: 5px;
  }
}

button {
  width: 100%;
  margin: 10px 0;
  padding: 5px;
  cursor: pointer;
}

.google-button {
  background-color: #DB4437;
  color: #fff;
}

.error-message {
  margin: 10px 0;
  padding: 6px;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border-radius: 2px;
  color: red;
}