$sidebar-length: 230px;

.dashboard-ui {
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: $sidebar-length auto;
}

.sidebar {
  // background: linear-gradient(45deg, rgb(28, 78, 128), rgba(2, 22, 56, 0.95));
  background: linear-gradient(60deg, #161616, #1b1b1b);
}

.main-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: #e4e4e4;
  background-color: #ececec;
}
