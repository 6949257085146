.info-block {
  padding: 10px 15px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);

  margin: 20px;

  .ib-heading {
    position: relative;
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 10px;
    font-weight: 700;
    letter-spacing: 0.8px;
    user-select: none;

    &-align-center {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      margin-left: unset;
      margin-top: 10px;
      margin-bottom: 18px;
    }

    &::before {
      left: -8px;
    }
    &::after {
      right: -8px;
    }
  }

  .ib-subheading {
    // font-size: 10.3px;
    text-transform: uppercase;
    font-weight: unset;
    min-width: 69px;
    text-align: center;
    color: rgb(172, 172, 172);
  }

  .ib-body {
    margin: 10px;
    // max-height: 300px;
    // overflow-y: auto;
  }

  @import "../colors/state-flat-colors";
  .ib-group-with-icon {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }

  .ib-group {
    padding: 4px 10px;
    border-left: solid 2.5px rgb(226, 226, 226);

    &:not(:last-of-type) {
      margin-bottom: 15px;
    }

    &.colorless {
      background-color: transparent;
      padding: 10px 0;
      border: none;
    }

    &.highlighted {
      .ib-row {
        .ib-property,
        .ib-value {
          font-size: 15px;
        }
      }
    }

    .ib-faded-label {
      text-align: center;
      color: rgb(211, 211, 211);

      &:not(:last-of-type) {
        margin-bottom: 3px;
      }

      svg {
        font-size: 20px;
      }
    }
  }

  .ib-group-icon {
    margin-right: 10px;
    color: #8d8d8d;
    font-size: 16px;

    &.ib-gi-active {
      color: $active-color;
    }
    &.ib-gi-transfer {
      color: $transferred-color;
    }
  }

  .ib-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;

    &.add-margin-top {
      margin-top: 7px;
    }

    &.align-right {
      justify-content: flex-end;
    }

    &-align-center {
      justify-content: center;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }

    .ib-column {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:not(:last-of-type) {
        margin-right: 48px;
      }

      &-flex-content {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    .ib-property,
    .ib-value {
      line-height: 18px;
      font-size: 14px;
    }

    .ib-property {
      margin-right: 6px;
      user-select: none;
    }

    .ib-value {
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // margin-top: 2px;

      &-mono {
        font-family: monospace;
      }

      .tf-pc {
        border-radius: 3px;
        display: inline-block;
        margin: 2px 5px 2px 0;

        &.new-tf {
          padding: 2px 5px;
          background-color: whitesmoke;
        }
      }

      &-no-margin-top {
        margin-top: 0;
      }
    }
  }

  .tiny-icon {
    font-size: 12px;
    margin: 0 8px 1px;
  }
}
