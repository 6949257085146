.swal-title {
  //   margin: 0px;
  //   font-size: 16px;
  //   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.21);
  //   margin-bottom: 28px;
}

.swal-modal {
  input,
  textarea {
    padding: 5px;
    border: 1px solid rgb(196, 196, 196);
    border-radius: 3px;
  }
}

.swal-custom-label {
  margin-top: 30px;
  width: 100%;
  // text-align: left;
  font-size: 16px;
  border-radius: 4px;

  .cl-em {
    font-family: monospace;
    font-weight: 600;
    margin-right: 5px;
  }
}

.swal-content {
  padding: 0 20px;
  margin-top: 5px;
  font-size: medium;
}

.swal-footer {
  background-color: rgb(245, 248, 250);
  margin-top: 32px;
  border-top: 1px solid #e9eef1;
  overflow: hidden;
}

@mixin button-layout {
  margin-left: 10px;
  user-select: none;
  padding: 8px 10px 6px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 0.8px;
  font-size: 13.5px;
  color: #fff;
}

.swal-button--confirm {
  background-color: #7cd1f9;
  border-bottom: 3px solid #53b7e6;
  text-shadow: 0px -2px #53b7e6;
}

.swal-button--cancel {
  @include button-layout;

  background-color: rgb(112, 112, 112);
  border-bottom: 3px solid rgb(80, 80, 80);
  text-shadow: 0px -2px rgb(80, 80, 80);

  &:hover {
    background-color: rgb(102, 102, 102) !important;
  }
}

.ka-reason,
.ka-remarks {
  text-align: left;
  width: 100%;
  p {
    width: 100%;
    font-weight: 600;
    margin-bottom: 3px;
  }
}

.ka-reason {
  input {
    width: 100%;
  }
  margin-bottom: 14px;
}

.ka-remarks {
  textarea {
    width: 100%;
    height: 80px;
    font-family: "Roboto";
    font-size: 13.5px;
  }
}

.custom-field {
  text-align: left;
  width: 100%;
  margin-bottom: 14px;

  p {
    font-weight: 600;
    margin-bottom: 3px;

    &.inline-label {
      display: inline-block;
      margin-right: 8px;
    }
  }

  input {
    width: 100%;
    padding: 3px 5px;
  }
}
