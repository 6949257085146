@mixin flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.calendar-wrapper {
  position: relative;
  width: 90%;
  height: 100%;
  min-height: 570px;
  margin: 0 auto;

  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-gap: 1px;

  .calendar-toolbar,
  .calendar-body {
    user-select: none;
    margin-bottom: 10px;
  }

  .calendar-toolbar {
    @include flex-row();
    justify-content: space-between;

    .ct-label-row {
      display: inline-block;
      font-size: 14px;

      .ct-label-property {
        font-weight: 600;
        margin-left: 1px;
        margin-right: 5px;
      }

      .ct-label-value {
        font-family: monospace;
      }
    }

    $calendar-control-height: 50px;
    .calendar-controls {
      position: relative;
      @include flex-row();

      height: $calendar-control-height;

      .cc-button,
      .cc-select-wrapper {
        margin-right: 5px;
      }

      .cc-button:last-of-type {
        margin-right: 0;
      }

      .cc-button {
        padding: 2px;
        cursor: pointer;
        color: rgb(163, 163, 163);
        // color: #10acf5;

        &:active {
          color: rgb(143, 143, 143);
          //   color: #089ee4;
        }

        &.cc-inactive {
          pointer-events: none;
          cursor: default;
          color: #cccccc;
          //   color: rgba(16, 172, 245, 0.25);
        }
      }

      .cc-select-wrapper {
        @include flex-row();
        justify-content: space-between;
        min-width: 90px;

        .cc-select {
          text-align: center;
          padding: 3px 5px;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.8px;
          background-color: #4aafff;
          box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);

          color: #fff;
          border-radius: 3px;

          &:hover {
            cursor: pointer;
          }

          &:active {
            box-shadow: 0 0 0.8px 0.8px rgba(0, 0, 0, 0.1);
            background-color: #45a9fa;
          }

          &:last-of-type {
            min-width: 39px;
          }
        }
      }

      // Custom Select Option Styles
      $cso-background-color: rgb(255, 255, 255);
      $cso-hover-color: #e4f1ff;
      .cc-select-option-wrapper {
        position: absolute;
        z-index: 9999;
        top: calc(#{$calendar-control-height} - 5px); //must be close to the height of calendar contorls
        right: 0;
        // left: 0;
        width: 200px;
        max-height: 200px;
        padding: 5px;
        background-color: $cso-background-color;
        box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        overflow-y: auto;
        text-align: center;

        &.select-wrapper-grid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
        }

        .cs-option {
          position: relative;
          padding: 5px 6px;
          margin: 5px;
          font-size: 14.5px;
          border-radius: 5px;
          cursor: pointer;
          color: rgb(107, 107, 107);

          &:hover {
            background-color: $cso-hover-color;
          }

          &.cso-selected {
            background-color: $cso-hover-color;
          }

          &.cso-invalid {
            color: rgb(196, 196, 196);
            pointer-events: none;

            &:hover {
              background-color: $cso-background-color !important;
              cursor: default;
            }
          }
        }
      }
    }
  }

  $cb-border-color: rgb(224, 224, 224);
  .calendar-body {
    background-color: #e8ebf2;

    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 31px auto;
    grid-gap: 1px;
    overflow: hidden;

    border: 1px solid $cb-border-color;
    border-radius: 4px;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);

    .c-header {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      padding: 6px 8px;
      // height: 31px;
      color: #fff;
      background: linear-gradient(60deg, #4aafff, #4cadfc, #4aafff);
    }

    .calendar-day {
      position: relative;
      color: rgb(56, 56, 56);
      background-color: #fff;
      overflow: hidden;

      .cday-content {
        position: absolute;
        padding: 3px 5px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .meta-span {
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        color: rgb(224, 224, 224);
        margin-bottom: 2px;
      }

      .meta-cdata {
        text-align: center;
        font-size: 12px;

        &.mc-green {
          color: #0db824;
        }
        &.mc-orange {
          color: #ffa500;
        }
      }

      .calendar-data {
        text-align: center;
        font-size: 14.4px;
        color: #08af1d;

        width: 100%;
        max-height: 100%;
        overflow-y: auto;

        p:not(:first-of-type) {
          margin-top: 1px;
        }
      }
    }

    .cd-faded {
      background-color: rgba(255, 255, 255, 0.72);
    }

    .cd-today {
      position: relative;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        content: "";

        border-left: 12px solid transparent;
        border-top: 12px solid #4aafff;
      }
    }
  }

  .calendar-description {
    font-size: 13px;
    color: rgb(136, 136, 136);
    // margin-top: 40px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .cdesc-row {
      text-align: left;

      &:not(:first-of-type) {
        margin-top: 2px;
      }
    }

    .cdesc-property {
      margin-right: 8px;
      user-select: none;
    }

    .cdesc-mono {
      font-family: monospace;
      font-size: 13.5px;
    }

    .cdesc-link {
      position: relative;
      cursor: pointer;
      color: rgb(90, 90, 90);
      user-select: none;

      &:hover {
        color: rgb(46, 46, 46);
      }
    }
  }
}
