.dashboard-loading-wrapper {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.14);
  color: #09283e;
  //   color: #ffffff;

  &.fixed-position {
    position: fixed !important;
    left: 0;
    top: 0;
  }

  .dashboard-loading {
    display: inline-block;
    text-align: center;

    span {
      font-size: 14px;
    }
  }
}
