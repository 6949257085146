.pagination {
  display: inline-block;
  // margin-right: 10px;

  .row-count-select,
  .page-count {
    display: inline-block;
    margin: 0 10px;
    user-select: none;
  }

  .page-controls {
    display: inline-block;
    margin-left: 10px;
    user-select: none;

    .control-icon {
      padding: 5px;
      cursor: pointer;
      // background-color: #727272;
      background: linear-gradient(60deg, #727272, #6d6d6d);
      border-radius: 3px;
      color: #fff;

      &-inactive {
        pointer-events: none;
        cursor: default;
        // background-color: #c2c2c2;
        background: linear-gradient(60deg, #c2c2c2, #bdbdbd);

        &:hover {
          // background-color: #c2c2c2 !important;
          background: linear-gradient(60deg, #c2c2c2, #bdbdbd) !important;
        }
        &:active {
          // background-color: #c2c2c2 !important;
          background: linear-gradient(60deg, #c2c2c2, #bdbdbd) !important;
        }
      }

      &:hover {
        background-color: #555555;
        background: linear-gradient(60deg, #555555, #4e4e4e);
      }
    }
  }
}
