.tooltip-wrapper {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  font-weight: 500 !important;
  text-transform: none !important;

  .tooltip-trigger {
    font-size: 17px;
    color: #a5caf1;
  }

  .tooltip-content {
    position: absolute;
    top: 28px;
    right: 0px;
    font-size: 14px;
    width: 200px;
    padding: 7px 5px;
    text-align: center;

    background-color: #eaf4ff;
    border: 1px solid rgba(204, 204, 204, 0.62);
    border-radius: 2px;
    box-shadow: 1px 2px 4px 1px rgba(48, 48, 48, 0.2);
  }

  &:hover {
    .tooltip-trigger {
      color: #76aae2;
    }
  }

  &:not(:hover) {
    .tooltip-content {
      display: none;
    }
  }
}
