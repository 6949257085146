.main-view {
  position: relative;
  width: 100%;
  height: calc(100vh - 128px);
  margin: 14px 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  &-without-footer {
    height: calc(100vh - 86px);
  }
}

.view-wrapper {
  width: stretch;
  height: inherit;
  // max-height: calc(100vh - 128px);
  padding: 0 14px;
  overflow-y: auto;
}

.empty-collection-label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  color: rgb(199, 199, 199);
  font-size: 25px;
}
