.mini-pop-up {
  position: relative;
  p {
    color: rgb(66, 66, 250);
    cursor: pointer;
    user-select: none;
  }

  &:hover {
    p {
      color: rgb(109, 109, 243);
    }
  }

  &.active-mpu {
    p {
      color: rgb(109, 109, 243) !important;
    }

    &::before {
      position: absolute;
      top: 1px;
      left: -5px;
      content: "";

      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;

      border-right: 8px solid rgb(161, 161, 161);
    }
  }

  .mpu-content {
    position: absolute;
    padding: 5px 10px;
    width: 205px;
    max-height: 90px;
    border-radius: 3px;

    z-index: 5000;
    top: 0;
    left: 1px;

    overflow-y: auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0.5px 0.5px 3px 0.6px rgba(0, 0, 0, 0.3);

    li {
      font-size: 14px;
      border-bottom: 1px solid rgb(219, 219, 219);
      margin-bottom: 3px;
      padding: 2px 0;
    }
  }
}
