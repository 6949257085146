// FONT FORMATTINGS
.font-size-20 {
  font-size: 20px !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.text-to-uppercase {
  text-transform: uppercase !important;
}

.font-color-faded {
  color: rgb(99, 99, 99) !important;
}

.font-color-green {
  color: #2db784 !important;
}

.font-color-red {
  // color: #ce293d !important; //Indian Red
  // color: #ff4040 !important; //Coral Red
  color: #cc7a7a !important; //Coral Red
}

.font-color-orange {
  // color: #ce293d !important;
  color: #e89963 !important;
}
