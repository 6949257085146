$active-nav-bg-color: #2c2c2c;

.sidebar-nav {
  // height: 100%;

  .nav-item {
    font-size: 15px;
    padding: 15px 0 15px 20%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    color: #fff;
    user-select: none;

    &:hover {
      cursor: pointer;
      background-color: $active-nav-bg-color;
    }
  }

  .active-nav {
    background-color: $active-nav-bg-color;
  }
}
