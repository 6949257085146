.timeline {
  .tl-action-bar {
    height: 30px;
    // background-color: rgb(90, 90, 90);
    // background: linear-gradient(60deg, #dddddd, #dddddd);
    background: linear-gradient(60deg, #e8e8e9, #e7e7e8);
    box-shadow: -0.5px -0.5px 1px 0.2px black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    color: rgb(46, 46, 46);
    font-size: 13px;
    letter-spacing: 0.5px;
    margin-bottom: 2px;

    .actions-wrapper {
      margin: 0 17px;

      .ab-filter,
      .ab-sort {
        display: inline-block;
      }

      .ab-sort {
        margin-left: 10px;
      }

      .ab-label {
        margin-right: 3px;
      }

      .ab-select {
        border-color: rgb(184, 184, 184);
        border-radius: 3px;
        padding: 1.4px;
        outline: none;
        font-size: 11.5px;
        color: rgb(73, 73, 73);
        letter-spacing: 0.5px;
      }
    }
  }

  .tl-body {
    height: calc(100vh - 82px);
    overflow-y: auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
  }

  .tl-event-node {
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: center;

    .event-timestamp {
      padding: 0 30px 30px 30px;
      text-align: right;
      height: 50px;
      width: 260px;
      margin-top: -1px;
    }

    @import "../../../common-styles/colors/state-gradient-colors";
    $tl-node-color: rgb(84, 171, 243);

    &:last-of-type {
      .event-list {
        // border-left: 2px solid transparent;
        &::after {
          content: "";
          position: absolute;
          left: -2px;
          top: 12px;
          height: calc(100% - 12px);
          width: 2px;
          // background-color: rgb(232, 235, 242);
          background: linear-gradient(180deg, $tl-node-color, #e8ebf2, #e8ebf2, #e8ebf2);
        }
      }
    }

    .event-list {
      position: relative;
      list-style: none;
      padding: 0 30px 30px 30px;
      border-left: 2px solid $tl-node-color;
      width: 600px;

      &::before {
        content: "";
        position: absolute;
        left: -7px;
        // top: 25px;
        height: 12px;
        width: 12px;
        background-color: $tl-node-color;
        border-radius: 50%;
      }

      .event-item {
        background-color: #fff;
        box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        margin: 1.5px 0 20px;
        overflow: hidden;
        // display: table;

        display: flex;
        flex-direction: column;
        line-height: 28px;

        .ei-heading {
          color: #fff;
          padding: 2px 20px;

          &-created {
            background: linear-gradient(60deg, $created-color, $created-color-2);
          }
          &-activated {
            // background-color: $active-color;
            background: linear-gradient(60deg, $active-color, $active-color-2);
          }
          &-re-activated {
            // background-color: $active-color;
            background: linear-gradient(60deg, #8cb10e, #8cb10e);
          }
          &-blocked {
            background: linear-gradient(60deg, $blocked-color, $blocked-color-2);
          }
          &-unblocked {
            background: linear-gradient(60deg, $active-color, $active-color-2);
          }
          &-leaked {
            background: linear-gradient(60deg, $leaked-color, $leaked-color-2);
          }
          &-refunded {
            background: linear-gradient(60deg, $refunded-color, $refunded-color-2);
          }
          &-transferred {
            background: linear-gradient(60deg, $transferred-color, $transferred-color-2);
          }
        }

        .ei-description {
          font-size: 14px;
          padding: 15px 20px;

          .ei-em {
            display: inline-block;
            height: 23px;
            padding: 1px 5px;
            font-family: monospace;
            font-weight: 600;
            background-color: rgb(245, 245, 245);
            border-radius: 4px;
          }

          .ei-note {
            padding: 1px 8px;
            background-color: rgba(135, 207, 235, 0.2);
            border-radius: 3px;
            display: table;
            margin-top: 5px;
          }

          em {
            font-style: normal;
            font-weight: 600;
          }
        }
      }
    }
  }
}
