$base-font-color: #2c2c2c;
$general-link-color: #198cff;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 10px;
}

body {
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.7rem;
  letter-spacing: 0.2px;

  color: $base-font-color;
}

h1,
h2,
h3,
h4,
h5 {
  // letter-spacing: 1.8px;
  // font-family: 'Roboto Slab', serif;
  font-weight: 600;
}

a {
  text-decoration: none;
  color: $general-link-color;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

ul,
ol {
  list-style: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}

select {
  border-color: rgba(27, 27, 27, 0.3);
  border-radius: 3px;
  padding: 2px 3px;
  display: inline;
}

select:disabled {
  color: rgb(194, 194, 194);
  border-color: rgb(218, 218, 218);
}
